import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { Button, ListItem, makeStyles } from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'

type NavItemProps = {
  className?: string
  href?: string
  icon?: any
  title?: string | ReactElement
}

const NavItem: React.SFC<NavItemProps> = ({ href, icon: Icon, title, ...rest }) => {
  const classes = useStyles()
  const { className } = rest
  return (
    <ListItem className={clsx(classes.item, className)} disableGutters {...rest}>
      <Button
        // activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href!}
      >
        {Icon && <Icon className={classes.icon} size={20} />}
        {typeof title === 'string' ? <span className={classes.title}>{title}</span> : title}
      </Button>
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    // paddingLeft: theme.spacing(1),
    // padding: '10px 8px',
    padding: theme.spacing(1),
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: '1.4em',
    // minWidth: theme.spacing(6)
  },
  title: {
    // marginLeft: theme.spacing(1),
    marginRight: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}))

export default NavItem
