import { Account, TransactionFee } from './'

export enum TransactionTypes {
  DEBIT = 'debit',
  CREDIT = 'credit',
}

export enum TransactionStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  SUCCEED = 'succeed',
}

export class Transaction {
  constructor(
    public id: string,
    public createdTime: Date,
    public accountId: string,
    public type: TransactionTypes,
    public status: TransactionStatus,
    public amount: number,
    public notes: string,
    public account?: Account,
    public transactionFees?: TransactionFee[],
  ) {}
}
