import { Transaction, Account, Partner } from './'

export enum CallbackStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  SUCCEED = 'succeed',
}

export class Callback {
  constructor(
    public id: string,
    public createdTime: Date,
    public partnerId: string,
    public accountId: string,
    public transactionId: number,
    public status: CallbackStatus,
    public requestUrl: string,
    public requestData?: { [k: string]: any },
    public requestTime?: Date,
    public responseTime?: Date,
    public responseStatus?: string,
    public responseData?: any,

    public partner?: Partner,
    public account?: Account,
    public transaction?: Transaction,
  ) {}
}
