import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { forwardRef, Ref } from 'react'
import { Helmet } from 'react-helmet-async'

type PageProps = {
  title?: string
  ref?: Ref<any>
  padded?: boolean
  className?: string
  [k: string]: any
}

const Page: React.FunctionComponent<PageProps> = forwardRef(({ children, title = '', padded, className, ...rest }, ref) => {
  const classes = useStyles()

  return (
    <div ref={ref} className={classNames(classes.root, padded && classes.rootPadded, className)} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
})

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: '100%',
    // minHeight: 600
  },
  rootPadded: {
    padding: theme.spacing(1),
  },
}))
export default Page
