import { Box, Grid, Hidden, Paper, Typography } from '@material-ui/core'
import Page from 'components/Page'
import React, { Fragment, useContext, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import Logo from 'components/Logo'
import classNames from 'classnames'
import { ContentDivider } from 'components/Divider'
import { AppContext } from 'contexts/AppContext'
import { useTranslation } from 'react-i18next'
import { RegisterForm } from './RegisterForm'
import { useLoginStyles } from './LoginPage'
import { ChevronRight } from '@material-ui/icons'

export const RegisterPage: React.FunctionComponent<{}> = () => {
  const { flash } = useContext(AppContext)
  const history = useHistory()
  const classes = useLoginStyles()
  const { t } = useTranslation()
  useEffect(() => {}, [])

  return (
    <Page title={t('user.signup')}>
      <div className={classes.container}>
        <Hidden smDown>
          <div className={classes.header}>
            <p className={classes.headerLogo}>
              <Logo
                size="large"
                // variant={Math.random() < 0.5 ? 'dark' : 'default'}
                variant={'dark'}
              />
              <h1 className={classes.headerTagline}>{t('app.tagline')}</h1>
            </p>
            <h1 className={classes.headerSubtitle}>{t('app.description')}</h1>
            <p className={classes.headerDesc}>{t('app.description1')}</p>
            <p className={classes.headerDesc}>{t('app.description2')}</p>
          </div>
        </Hidden>
        <div className={classes.wrapper}>
          <Fragment>
            <Hidden mdUp>
              <NavLink to="/" className={`${classes.brand} ${classes.outer}`}>
                <Logo />
              </NavLink>
            </Hidden>

            <Paper className={classNames(classes.paperWrap, classes.petal)}>
              <Hidden smDown>
                <div className={classes.topBar}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <NavLink to="/" className={classes.brand} style={{ margin: 0 }}>
                        <Logo />
                        {/* {t('login.title')} */}
                      </NavLink>
                    </Grid>
                    <Grid item>
                      <NavLink to="/auth/signin" className={classes.buttonLink}>
                        <Box justifyContent="center" alignItems="center" fontSize={16} marginTop={1}>
                          {t('user.login_now')}
                          <ChevronRight className={classes.icon} fontSize="small" style={{ paddingTop: 7 }} />
                        </Box>
                      </NavLink>
                    </Grid>
                  </Grid>
                </div>
              </Hidden>
              <Box marginY={5}>
                <Typography variant="h4" className={classes.title} gutterBottom>
                  {t('user.signup')} / {t('user.register')}
                </Typography>
                <Typography variant="caption" className={classes.subtitle} gutterBottom align="center">
                  {t('user.signup_instruction')}
                </Typography>
              </Box>
              <section className={classes.socmedSideLogin}>
                <div className={classes.btnArea}></div>
                <ContentDivider content={t('user.signup_with_email')} />
              </section>
              <section className={classes.formWrap}>
                <RegisterForm
                  onRegisterSucceed={() => {
                    flash(t('user.signup_succeed'))
                    history.replace('/auth/signin')
                  }}
                />
              </section>
            </Paper>
          </Fragment>
        </div>
      </div>
    </Page>
  )
}
