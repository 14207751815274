import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, makeStyles, TextField } from '@material-ui/core'
import { AppContext } from 'contexts'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Api from 'utils/Api'
import Env from 'utils/Env'
import ReCAPTCHA from 'react-google-recaptcha'

type RegisterData = {
  name: string
  email: string
  password: string
  confirmPassword: string
  agreed: boolean
}

export const RegisterForm: React.FunctionComponent<{
  onRegisterSucceed: () => void
}> = ({ onRegisterSucceed }) => {
  const { handleError } = useContext(AppContext)
  const { t } = useTranslation()
  const [token, setToken] = useState<string>()

  const { register, handleSubmit, errors, control, watch } = useForm<RegisterData>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      agreed: false,
    },
  })

  const password = watch('password')
  const agreed = watch('agreed')

  const onSubmit = (values: RegisterData) => {
    if (Env.recaptchaKey && !token) return

    Api.request({
      method: 'POST',
      url: '/users/register',
      data: {
        name: values.name,
        email: values.email,
        password: values.password,
        partnerId: Env.partnerId,
        token: token ? token : 'xxxxxx',
      },
    })
      .then(res => {
        onRegisterSucceed()
      })
      .catch(handleError)
  }

  const onRecaptcha = (token: string | null) => {
    setToken(token || undefined)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" aria-autocomplete="none">
      <FormGroup>
        <Controller as={<input />} type="hidden" control={control} name={`csrf`} />

        <FormControl fullWidth={true} margin="normal">
          <Controller
            as={TextField}
            control={control}
            name="name"
            variant="outlined"
            label={t('user.full_name')}
            placeholder={t('user.full_name_placeholder')}
            rules={{
              required: t('errors.required').toString(),
              pattern: {
                value: /^[a-z ,.'-]+$/i,
                message: t('errors.invalid_name_input'),
              },
            }}
            FormHelperTextProps={{ error: errors.name ? true : false }}
            helperText={errors.name?.message}
          />
        </FormControl>

        <FormControl fullWidth={true} margin="normal">
          <Controller
            as={TextField}
            control={control}
            name="email"
            variant="outlined"
            label={t('user.email')}
            autoComplete="off"
            placeholder={t('user.email_placeholder')}
            rules={{
              required: t('errors.required').toString(),
              pattern: {
                // value: /^[\w-_\.]+@[a-zA-Z_]+?\.[a-zA-Z\.]{2,}$/,
                value: /(.*?)+/i,
                message: t('errors.invalid_email_input'),
              },
            }}
            FormHelperTextProps={{ error: errors.email ? true : false }}
            helperText={errors.email?.message}
          />
        </FormControl>

        <FormControl fullWidth={true} margin="normal">
          <Controller
            as={TextField}
            control={control}
            name="password"
            type="password"
            variant="outlined"
            label={t('user.password')}
            placeholder={t('user.new_password_placeholder')}
            rules={{
              required: t('errors.required').toString(),
              minLength: {
                value: 8,
                message: t('errors.input_min_length', { length: 8 }).toString(),
              },
            }}
            FormHelperTextProps={{ error: errors.password ? true : false }}
            helperText={errors.password?.message}
          />
        </FormControl>

        <FormControl fullWidth={true} margin="normal">
          <Controller
            as={TextField}
            control={control}
            name="confirmPassword"
            type="password"
            variant="outlined"
            label={t('user.confirm_password')}
            placeholder={t('user.confirm_new_password_placeholder')}
            rules={{
              required: t('errors.required').toString(),
              validate: value => value === password || t('errors.confirm_password_missmatch').toString(),
            }}
            FormHelperTextProps={{
              error: errors.confirmPassword ? true : false,
            }}
            helperText={errors.confirmPassword?.message}
          />
        </FormControl>

        <FormControl fullWidth={true} margin="normal">
          <FormControlLabel
            label={<Box textAlign="left">{t('user.terms_agreed')}</Box>}
            control={
              <Controller
                control={control}
                name="agreed"
                defaultValue={false}
                render={props => <Checkbox checked={props.value} onChange={e => props.onChange(e.target.checked)} />}
                rules={
                  {
                    // validate: (val) =>
                    //   val ? true : t('errors.required').toString()
                  }
                }
              />
            }
          />
        </FormControl>
      </FormGroup>

      <Box marginY={2} display="flex" justifyContent={'center'}>
        {Env.recaptchaKey && <ReCAPTCHA sitekey={Env.recaptchaKey} onChange={onRecaptcha} size="normal" />}
      </Box>

      <FormControl margin="normal">
        <Button color="primary" size="large" type="submit" variant="contained" disabled={!agreed}>
          {t('user.signup')}
        </Button>
      </FormControl>
    </form>
  )
}
